<template>
    <div>
        <l-map 
            ref="myMap"
            style="height: 400px" 
            :zoom="zoom" 
            :center="center"
            @click="onMapClick"
        >
            <l-tile-layer :url="url" attribution="Grupo Innofarm"></l-tile-layer>
            <l-control 
                position="topright" 
                class="text-search-address" 
                v-if="searchInputs"
            >
                <v-row>
                    <v-col cols=12 md=6>
                        <v-text-field
                            v-model="searchShippingAddress"
                            outlined                    
                            dense
                            background-color="white"
                            placeholder="Buscar por dirección"
                            append-icon="mdi-magnify"
                            clearable
                            hide-details
                            solo
                            @click:clear="addressesFound=[]"
                            @click="searhAddress"
                            @keyup="searhAddress"
                            :loading="loadingSearch"
                        >
                        </v-text-field>
                        <div class="append-results-address">
                            <v-list 
                                dense 
                                v-if="addressesFound.length"
                            >
                                <v-list-item 
                                    v-for="(address, x) in addressesFound" 
                                    :key="x" 
                                    @click="selectAddress($event,address)"
                                >
                                    <v-list-item-content>
                                        <v-list-item-subtitle>{{ address.address }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>

                    </v-col>
                    <v-col cols=12 md=6>
                        <v-text-field
                            v-model="searchShippingAddressLatLng"
                            outlined                    
                            dense
                            background-color="white"
                            placeholder="Buscar por latitud y longitud"
                            append-icon="mdi-magnify"
                            clearable
                            hide-details
                            solo
                            @click="searhAddressLatLng"
                            @keyup="searhAddressLatLng"
                            :loading="loadingSearch"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </l-control>
            <l-control 
                position="bottomleft" 
                class="save-address" 
                v-if="makers.length && actionData" 
            >
                <v-card
                    max-width="400"
                    class="mx-auto"
                >
                    <v-list class="py-0">
                        <v-list-item @click="savePoint">
                        <v-list-item-avatar>
                            <v-icon color="primary">mdi-24px mdi-content-save-all</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-subtitle >
                                <v-btn><b>GUARDAR LA DIRECCIÓN</b></v-btn>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </l-control>
            <l-marker
                ref="marker"
                :draggable=true
                :update:visible=true
                v-for="(m, x) in makers"
                :key=x
                :lat-lng="m.latLng"
                @dragend="onDragEnd"
            >
                <l-tooltip :options="{ permanent: true, interactive: true }">
                    <div> <b>DIRECCIÓN:</b> </div>
                    <div> {{m.label}} </div>
                </l-tooltip>
            </l-marker>
        </l-map>
    </div>
</template>

<script>

var intervalSearchAddress=""

import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LControl } from 'vue2-leaflet';
import { mapState, mapActions, mapMutations } from 'vuex'
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import { latLng } from "leaflet";
import Vue from 'vue'

export default {
    name:'Map',
    components:{
        LMap,
        LTileLayer,
        LMarker,
        LPopup, 
        LTooltip,
        LControl
    },
    props:{
        makers:{
            type: Array,
            default:() =>[{
                latLng: [0,0],
                label: ''
            }]
        },
        saveClientUbication:{
            type: Boolean,
            default: false
        },
        actionData:{
            type: Boolean,
            default: true
        },
        searchInputs:{
            type: Boolean,
            default: true
        },
    }, 
    data: ()=> ({
        loadingSearch: false,
        searchShippingAddress:null,
        searchShippingAddressLatLng:'',
        zoom: 13,
        addressesFound:[],
        center: latLng(0, 0),
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        provinces:[],
    }),
    watch:{

        makers:function(val){
            console.log('val',val)

            if(typeof val[0] != 'undefined')
                this.center = latLng(val[0].latLng[0],val[0].latLng[1])
        },

        provinces:function(val){

        },

    },
    computed:{

        ...mapState('master',['paramAlertQuestion','objGeoCoding']),

    },
    methods:{

        ...mapActions('master',['requestApi']),

        ...mapMutations('master',['setUrl']),
        
        onDragEnd(point){
           
            this.objGeoCoding.provider.search({query:`${point.target._latlng.lat},${point.target._latlng.lng}`})
            .then(res => {
                console.log('hola')
                this.$emit('setPointShippment',{
                    latLng: [point.target._latlng.lat,point.target._latlng.lng],
                    label: res[0].raw.formatted_address
                })

                this.zoom=20

            })
            
        },

        saveClientPoint(data,point,update,observaciones){
            
            let dataPoint = {
                province :[],
                country:'',
                city :'',
                street :'',
                street2 :'',
                update
            }

            data.forEach(obj => {
                
                obj.types.forEach(type => {
                
                    if(type == 'administrative_area_level_1' || type == 'administrative_area_level_2'){
                        dataPoint.province.push(obj.long_name)
                        return
                    }

                    if(type == 'country'){
                        dataPoint.country= obj.long_name
                        return
                    }

                    if(type == 'locality' || type=='sublocality'){
                        dataPoint.city+= obj.long_name+' '
                        return
                    }

                    if(type == 'route' || type=='plus_code' || type=='street_number'){
                        dataPoint.street+= obj.long_name+' '
                        return
                    }
                    if(type == 'establishment'){
                        dataPoint.street2= obj.long_name
                        return
                    }
                        
                })
                
            })

            this.$emit('updatePoint',{dataPoint,point,observaciones})

        },

        searhAddress(){
            
            if(this.searchShippingAddress!='' && this.searchShippingAddress!=null){           
                clearInterval(intervalSearchAddress)
                intervalSearchAddress = setTimeout(()=>{  
                    this.loadingSearch=true
                    console.log(this.searchShippingAddress,this.objGeoCoding)
                    this.objGeoCoding.provider.search({query:`${this.searchShippingAddress}`})
                    .then(res => {
                        this.addressesFound=[]
                        res.map(obj => {
                            this.addressesFound.push({
                                address: obj.raw.formatted_address,
                                lat: obj.raw.geometry.location.lat,
                                lng: obj.raw.geometry.location.lng,
                                address_components: obj.raw.address_components
                            })
                        })
                        console.log(this.addressesFound)
                        this.loadingSearch=false
                        this.zoom=20 
                    })
                },500)
            }
            
        },

        selectAddress(e, address){
            console.log('e',e)
            e.preventDefault()
            e.stopPropagation()
            
            this.addressesFound=[]
            
            console.log(address.lat(),address.lng())
            console.log('hola2')
            this.$emit('setPointShippment',{
                latLng: [address.lat(),address.lng()],
                label: address.address
            })

            this.zoom=20
            console.log(' this.$refs.marker', this.$refs,this.$refs.marker)
            /* if(typeof this.$refs.marker != 'undefined'){
                this.$refs.marker[0].setLatLng({lat: address.lat(), lng: address.lng()}) 
                this.$refs.marker[0].mapObject._tooltip._contentNode.innerHTML =`<div><b>DIRECCIÓN:</b></div><div> ${address.address} </div>`
            } */
            this.center = latLng(address.lat(),address.lng())
            //this.saveClientPoint(address.address_components,{lat:address.lat,lng:address.lng },false)
        },

        searhAddressLatLng(){

            let latLng = this.searchShippingAddressLatLng.split(',')
            
            if(latLng.length >= 2){
                
                //this.$emit('emptyPointShippment')

                this.objGeoCoding.provider.search({query:`${latLng[0]},${latLng[1]}`})
                .then(res => {
                    console.log('hola3')
                    this.$emit('setPointShippment',{
                        latLng: [latLng[0],latLng[1]],
                        label: res[0].raw.formatted_address
                    })

                    this.zoom=20
                })
                
            }

        },

        onMapClick(event){
            console.log(event)

            this.objGeoCoding.provider.search({query:`${event.latlng.lat},${event.latlng.lng}`})
            .then(res => {
                console.log('hola4')
                this.$emit('setPointShippment',{
                    latLng: [event.latlng.lat,event.latlng.lng],
                    label: res[0].raw.formatted_address
                })
          
            })
        },

        savePoint(point){

            if(!this.actionData){
                this.$emit('noUpdatePoint',true)
                return false
            }

            let html = `<div class="col-12">
                            <input type="checkbox" checked id="actualiza_direccion" > Seleccione este check para actualizar la ubicación, déjelo en blanco para crear una nueva ubicación
                        </div>
                        <div class="col-12">
                            <textarea  id="observaciones" placeholder="Ingrese la referencia de la dirección" rows="3" cols="35" style="border: 1px solid silver;padding: 10px;"></textarea>
                        </div>`

            Vue.swal({
                icon:'info',
                title: 'Esto modificará la ubicación seleccionada',
                html,
                //input: 'checkbox',
                //inputValue: 1,
                //inputPlaceholder: 'Seleccione este check para actualizar la ubicación, déjelo en blanco para crear una nueva ubicación',
                confirmButtonText: 'Continue <i class="fa fa-arrow-right"></i>',
                showCancelButton: true,
            }).then(result => {

                let valueCheck = document.getElementById('actualiza_direccion').checked
                let observaciones = document.getElementById('observaciones').value
        
                if(!result.isConfirmed)
                    this.$emit('noUpdatePoint',true)
                
                if(result.isConfirmed){
                    
                    const {
                        latLng
                    } = this.$refs.marker[0]
             
                    this.objGeoCoding.provider.search({query:`${latLng[0]},${latLng[1]}`})
                    .then(res => {
             
                        if(typeof res[0] != 'undefined'){

                            this.saveClientUbication && this.saveClientPoint(res[0].raw.address_components,{lat:latLng[0],lng:latLng[1] }, valueCheck, observaciones)
                            
                        }else{

                            alert('Dirección no encontrada, seleccione nuevamente el punto de referencia')
                            this.$emit('noUpdatePoint',true)

                        }
                        
                    })

                }

            })

        }

    },
    mounted(){
        console.log('makers',this.makers)
        this.$nextTick(() => {
            this.$refs.myMap.mapObject.invalidateSize();
            console.log('this.makers',this.makers,this.makers)
            this.center= latLng(this.makers[0].latLng[0], this.makers[0].latLng[1])
            if(!this.searchInputs){
                this.zoom=15
            }
            
        })

    },
    created(){
        
        delete Icon.Default.prototype._getIconUrl;
        Icon.Default.mergeOptions({
            iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
            iconUrl: require('leaflet/dist/images/marker-icon.png'),
            shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        });
        
    }
}
</script>

<style>
    .text-search-address {
        right: 85%;
        position: relative;
    }

    .append-results-address{
        margin-top: 0!important;
        position: absolute!important;
        top: 43px!important;
    }
</style>